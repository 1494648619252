import { Link } from 'gatsby';
import * as React from 'react';
import styled from '@emotion/styled';
import { css } from 'emotion';

import SiteNavLogo from '../components/header/SiteNavLogo';
import Wrapper from '../components/Wrapper';
import IndexLayout from '../layouts';
import { colors } from '../styles/colors';
import { inner, outer, SiteHeader } from '../styles/shared';

const SiteNavCenter = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .site-nav-logo {
    margin-right: 0;
  }
`;

const ErrorTemplate = css`
  padding: 7vw 4vw;
`;

const ErrorCode = styled.h1`
  margin: 0;
  font-size: 12vw;
  line-height: 1em;
  letter-spacing: -5px;
  opacity: 0.3;
`;

const ErrorDescription = styled.p`
  margin: 0;
  color: ${colors.midgrey};
  font-size: 3rem;
  line-height: 1.3em;
  font-weight: 400;
`;

const ErrorLink = css`
  display: inline-block;
  margin-top: 5px;
`;

const NotFoundPage: React.FunctionComponent = () => {
  return (
    <IndexLayout>
      <Wrapper>
        <main id="site-main" className={`${ErrorTemplate} ${outer}`}>
          <div className={`${inner}`}>
            <section style={{ textAlign: 'center' }}>
              <ErrorCode>404</ErrorCode>
              <ErrorDescription>Page not found</ErrorDescription>
              <Link className={`${ErrorLink}`} to={''}>
                Go to the front page →
              </Link>
            </section>
          </div>
        </main>
        <aside className={`${outer}`}>
          <div className={`${inner}`} />
        </aside>
      </Wrapper>
    </IndexLayout>
  );
};

export default NotFoundPage;
